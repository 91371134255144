import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '26, 35, 126',
		'primary-dark': '0, 0, 81',
		'accent': '255, 95, 7',
		'accent-plus': '0, 0, 0',
	},
});
